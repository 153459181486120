const getters = {
  contacts: state => state.sms.contacts,
  messages: state => state.sms.messages,
  checkList: state => state.sms.checkList,
  sendList: state => state.sms.sendList,
  unreadMessage: state => state.sms.unreadMessage,
  selectContact: state => state.sms.selectContact,
  lastMessages: state => state.sms.lastMessages,
  uploadMessages: state => state.sms.uploadMessages,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  expired: state => state.user.expired,
  password: state => state.user.password,
  limitRepeat: state => state.user.limitRepeat,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  userId: state => state.user.userId,
  mutilPhones: state => state.phone.mutilPhones,
  phones: state => state.phone.phones,
  fromPhone: state => state.phone.fromPhone,
  integral: state => state.account.integral,
  accountId: state => state.account.accountId,
}
export default getters
  