import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import NProgress from 'nprogress'
import 'element-ui/lib/theme-chalk/index.css'
import './permission'
import "bootstrap-icons/font/bootstrap-icons.css"
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false })
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
