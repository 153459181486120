import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index')
  },
  {
    path: '/main',
    component: Layout,
    redirect: '/message',
    children: [
      {
        path: '/message',
        name: 'message',
        component: () => import('@/views/message/index')
      },
      {
        path: '/archive',
        name: 'archive',
        component: () => import('@/views/archive/index')
      },
      {
        path: '/translate',
        name: 'translate',
        component: () => import('@/views/translate/index')
      },
      {
        path: '/icon',
        name: 'icon',
        component: () => import('@/views/icon/index')
      },
      {
        path: '/import',
        name: 'import',
        component: () => import('@/views/import/index')
      },
      {
        path: '/question',
        name: 'question',
        component: () => import('@/views/question/index')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index')
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  { path: '*', redirect: '/404', hidden: true }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
