<template>
  <div class="chatroom-box">
    <div class="send-content" ref="sendContent" @scroll="scrollContent">
      <div v-if="startLoad" class="load-message-box">
        <i class="el-icon-loading"></i>
      </div>
      <div :class="`message-line ${item.isLeft?'left':'right'}`" v-for="(item, index) in showMessages" :key="index">
        <div class="receiver-message" v-if="item.isLeft">
          <div class="icon-box" :style="`background-color: ${item.color}`">
            <i class="bi bi-person-fill"></i>
          </div>
          <div class="message-content">
            {{ item.body }}
            <p class="contact-info">{{ item.to }} <span class="padding"></span> {{ item.time }}</p>
          </div>
          <div class="padding"></div>
        </div>
        <div class="sender-message" v-else>
          <div class="padding"></div>
          <i v-if="item.status==='sending'" class="el-icon-loading" />
          <i v-else-if="item.status==='error'" class="el-icon-warning" />
          <i v-else-if="item.status==='recharge'" class="el-icon-s-goods" />
          <i v-else-if="item.status==='forbidden'" class="el-icon-remove" />
          <i v-else-if="item.status==='frequently'" class="el-icon-time" />
          <i v-else-if="item.status==='excess'" class="el-icon-odometer"></i>
          <i v-else-if="item.status==='no_enter'" class="el-icon-s-opportunity"></i>
          <i v-else-if="item.status==='unbind'" class="el-icon-link"></i>
          <i v-else-if="item.status==='limit_day'" class="el-icon-remove-outline"></i>
          <div class="message-content">
            {{ item.body }}
            <p class="contact-info">{{ item.from }} <span class="padding"></span> {{ item.time }}</p>
          </div>
          <div class="icon-box">
            <i class="bi bi-person-fill"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="send-btns">
      <div v-loading="sendLoading" v-if="mutilSending || sendLoading || delayTime > 0"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(217, 217, 217, 0.9)" 
        class="delay-mask">
        <p v-if="!sendLoading" class="timeout-text">
          {{ messageQueue.length > 0 ? `Next message will` : 'Messages can' }} be sent after (<span class="delay-time">{{ delayTime }}</span>) s
        </p>
      </div>
      <div class="icon-box" v-show="false" @click="openFloder">
        <i class="bi bi-image"></i>
      </div>
      <div class="send-input">
        <textarea ref="inputArea" class="input-message" maxlength="350" @keydown="inputKey" rows="1" placeholder="Type a message" v-model="input"></textarea>
      </div>
      <div @click="sendMessage" :class="`${input===''?'send-box':'icon-box can-send'}`">
        <i class="bi bi-send"></i>
      </div>
      <span class="limit-char">{{ input.length }}/350</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { selectMessage, insertContact, selectContact, updateLastMessage, loadLastMessages } from '@/utils/indexdb'
import { formatDate } from '@/utils/time'
import { send, checkMessage } from '@/api/message'
import { stringToColor } from '@/utils/color'
import { sortHandler } from '@/utils/sort'
import { uuid } from '@/utils/uuid'
import moment from 'moment'

const END_STATUS = ['ok', 'error', 'recharge', 'forbidden', 'frequently', 'excess', 'unbind', 'no_enter', 'limit_day']
const WAIT_STATUS = ['wait']
const SEND_DELAY_STATUS = ['ok', 'excess', 'no_enter'] // send next message need delay 10s status
const SAVE_HISTORY_STATUS = ['ok']


export default {
  name: 'chatroom',
  computed:{
    ...mapGetters([
      'contacts',
      'selectContact',
      'fromPhone',
      'messages',
      'checkList',
      'sendList',
      'mutilPhones',
      'limitRepeat',
      'uploadMessages'
    ]),
    showMessages() {
      const length = this.messages.length
      if (length < this.showIndex) {
        return this.messages
      }
      let start = Math.max(length - this.showIndex, 0)
      return this.messages.slice(start)
    }
  },
  watch: {
    uploadMessages: function(messages) {
      if (messages && messages.length > 0) {
        const running = this.messageQueue.length > 0
        for (let i = 0; i < messages.length; i++) {
          const message = messages[i];
          this.messageQueue.push(message)
        }
        if (!running) {
          this.configDelayTime = 3
          this.delayReduce()
        }
        this.$store.dispatch('sms/SetUploadMessages', [])
      }
    },
    selectContact: function(newVal) {
      let messages = []
      if (this.fromPhone && newVal) {
        messages = selectMessage(this.fromPhone.fullNumber, newVal.phone)
      }
      this.$store.dispatch('sms/SetMessage', messages)
      this.scrollBottom()
    },
    checkList: function(newVal, oldVal) {
      if (newVal.length > oldVal.length && newVal.length > 0) {
        const msg = newVal[0]
        this._checkStatus(msg, 0, -1)
      }
    },
    sendList: function(newVal, oldVal) {
      if (newVal.length > oldVal.length && newVal.length > 0) {
        const msg = newVal[0]
        this.sendLoading = true
        send(msg).then((response) => {
          const { code, data } = response
          if (code === 200) {
            this.configDelayTime = data.sendDelay
            this.$store.dispatch('user/setLimitRepeat', data.limitRepeat)
            this.$store.dispatch('sms/PushCheckMessage', msg)
          }
        }).catch((e) => {
          console.error(e)
          this.sendLoading = false
          this.$message.error('send message error')
          this.$store.dispatch('sms/UpdateMessage', {...msg,
            status: 'error'
          })
        })
      }
    }
  },
  data() {
    return {
      input: '',
      configDelayTime: 0,
      delayTime: 0,
      sendLoading: false,
      mutilSending: false,
      sending: false,
      showIndex: 0,
      showStep: 5,
      startLoad: false,
      startLoadId: null,
      messageQueue: [],
      history: []
    }
  },
  created() {
    const messageHistroy = localStorage.getItem('msg_history')
    if (messageHistroy) {
      this.history = JSON.parse(messageHistroy)
    } else {
      this.history = []
    }
    this.$nextTick(() => {
      this.showStep = Math.min(this.$refs.sendContent.clientHeight / 102, 5)
      this.showIndex = 3 + this.showStep
    })
  },
  methods: {
    scrollContent(e) {
      if (this.showIndex >= this.messages.length) {
        return
      }
      this.startLoadId && clearTimeout(this.startLoadId)
      const { scrollTop } = e.target
      if (scrollTop <= 0) {
        this.startLoadId = setTimeout(() => {
          this.startLoad = true
          setTimeout(() => {
            let newIndex = this.showIndex * 2
            this.showIndex = Math.min(this.messages.length, newIndex)
            this.startLoad = false
            this.scrollTo(50)
          }, 800);
        }, 300);
      }
    },
    scrollTo(step) {
      this.$nextTick(() => {
        const domWrapper = this.$refs.sendContent
        domWrapper.scrollTop = step
      })
    },
    scrollBottom() {
      this.$nextTick(() => {
        const domWrapper = this.$refs.sendContent
        const { scrollHeight } = domWrapper
        domWrapper.scrollTop = scrollHeight + domWrapper.clientHeight
      })
    },
    saveHistory(message) {
      if (!this.history.includes(message)) {
        this.history.unshift(message)
        const oldLength = this.history.length
        const messages = this.history.slice(0, Math.max(0, Math.min(oldLength, this.limitRepeat)))
        this.history = messages
        localStorage.setItem('msg_history', JSON.stringify(messages))
      }
    },
    _checkStatus(msg, count, timeoutId) {
      if (count > 300) {
        clearTimeout(timeoutId)
        return
      }
      timeoutId = setTimeout(() => {
        checkMessage(msg).then((data) => {
          // no check status
          if (END_STATUS.includes(data.status)) {
            clearTimeout(timeoutId)
            this.$store.dispatch('sms/UpdateMessage', {...msg,
              status: data.status
            })
            this.$store.dispatch('sms/RemoveCheckMessage', msg)
            this.sendLoading = false

            // end check
            if (SEND_DELAY_STATUS.includes(data.status)) {
              if (SAVE_HISTORY_STATUS.includes(data.status)) {
                this.saveHistory(msg.text)
              }
              this.delayReduce()
            }
          } else if (WAIT_STATUS.includes(data.status)) {
            this._checkStatus(msg, count + 1, timeoutId)
          }
        }).catch(() => {
          clearTimeout(timeoutId)
          this.sendLoading = false
          this.$store.dispatch('sms/UpdateMessage', {...msg,
            status: 'error'
          })
          this.$store.dispatch('sms/RemoveCheckMessage', msg)
        })
      }, 200);
    },
    delayReduce() {
      this.delayTime = this.configDelayTime
      let delayInterval = setInterval(() => {
        this.delayTime--
        if (this.delayTime <= 0) {
          this.delayTime = 0
          clearInterval(delayInterval)
          const msg = this.messageQueue.pop()
          if (msg) {
            this._sendMsg(msg.number, msg.phone, msg.fullNumber, msg.text)
          } else {
            this.mutilSending = false
          }
        }
      }, 1000)
    },
    openFloder() {
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = 'image/*'
      input.click()
    },
    pushMessage(item) {
      this.$store.dispatch('sms/PushMessage', item)
      this.showIndex++
      this.$nextTick(() => {
        const domWrapper = this.$refs.sendContent
        const { scrollHeight } = domWrapper
        domWrapper.scrollTop = scrollHeight + domWrapper.clientHeight
      })
    },
    inputKey (e) {
      if (e.key === 'Backspace') {
        return
      }
      if (this.input.length >= 350) {
        e.preventDefault()
        return
      }
      if (!e.isComposing && e.key === 'Enter') {
        this.sendMessage()
        e.preventDefault()
      }
    },
    _checkParams() {
      if (this.sending) {
        throw new Error('frequent operation.')
      }
      if (!this.input) {
        throw new Error('can not send empty content.')
      }
      if (!this.fromPhone) {
        throw new Error('select phone.')
      }
      if (this.fromPhone.expired < 0) {
        throw new Error('select phone expired.')
      }
      if (this.sendLoading) {
        this.input = ''
        throw new Error('send delaying.')
      }
      if (this.mutilPhones.length > 100) {
        throw new Error('Numbers sent in batches cannot exceed 100.')
      }
    },
    isStranger(phone) {
      for (let j = 0; j < this.contacts.length; j++) {
        const contact = this.contacts[j];
        if(contact.phone === phone) {
          return false
        }
      }
      return true
    },
    async sendMessage() {
      try {
        this._checkParams()
      } catch (e) {
        this.$message.warning(e.message)
        return
      }
      const text = this.input
      if (this.mutilPhones.length > 0) {
        const phones = this.mutilPhones
        this.sending = true
        this.input = ''
        this.messageQueue = []
        this.mutilSending = true
        for (let i = 0; i < phones.length; i++) {
          const phone = phones[i]
          const { number, fullNumber } = this.fromPhone
          this.messageQueue.push({
            number,
            phone,
            fullNumber,
            text
          })
        }
        const msg = this.messageQueue.pop()
        this._sendMsg(msg.number, msg.phone, msg.fullNumber, msg.text)
        this.sending = false
      } else {
        if (this.input && this.selectContact) {
          this.sending = true
          const { number, fullNumber } = this.fromPhone
          this._sendMsg(number, this.selectContact.phone, fullNumber, this.input)
          this.input = ''
          this.sending = false
        }
      }
    },
    _checkRepeat(to, input) {
      // check if a new contact
      let isNewContact = true
      for (let i = 0; i < this.contacts.length; i++) {
        const contact = this.contacts[i]
        if (contact.phone === to) {
          isNewContact = false;
          break
        }
      }
      if (isNewContact) {
        if (this.history.includes(input)) {
          return true
        }
      }
      return false
    },
    _sendMsg(from, to, fullNumber, input) {
      if (this._checkRepeat(to, input)) {
        const prop = {
          duration: 3000,
          dangerouslyUseHTMLString: true,
          message: `<p style='margin-bottom: 10px'>Duplicate message sending limit</p><i>${to}: ${input}</i>`
        }
        this.$message(prop)
        this.configDelayTime = 3
        this.delayReduce()
        return
      }
      const messageId = uuid()
      const status = 'sending'
      this.pushMessage({
        from,
        body: input,
        isLeft: false,
        color: stringToColor(from),
        time: moment().format('LTS'),
        status,
        messageId
      })
      insertContact(fullNumber, to)
      const contacts = selectContact(fullNumber)
      for (let i = 0; i < contacts.length; i++) {
        const contact = contacts[i];
        if (contact.phone === to) {
          contact.focus = true
          break
        }
      }
      const msg = {
        text: input,
        number: from,
        fullNumber,
        to,
        status,
        messageId
      }
      this.$store.dispatch('sms/PushSendMessage', msg)
      const lastMessage = { to, from: fullNumber, body: 'You: ' + input }
      lastMessage.time = formatDate(new Date())
      //更新最近的消息
      updateLastMessage(lastMessage)
      const lastMessages = loadLastMessages(fullNumber)
      const sortArr = lastMessages.sort(sortHandler('timestamp'))
      this.$store.dispatch('sms/SetLastMessages', sortArr)
      this.$store.dispatch('sms/SetContact', contacts)
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss">
.chatroom-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .send-content {
    flex: 1;
    padding: 16px;
    overflow-y: auto;
    position: relative;
    .load-message-box {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      height: 40px;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e0f2f1;
    }
    .left {
      text-align: left;
      .contact-info {
        left: 20px;
        span {
          display: inline-block;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: #5f6368;
          margin: 0 4px;
        }
      }
    }
    .right {
      text-align: right;
      .message-content {
        background-color: #e0f2f1;
        color: #004d40;
      }
      .icon-box {
        background-color: rgb(138, 138, 138);
      }
      .contact-info {
        right: 20px;
      }
    }
    .message-line {
      margin: 36px 10px;
      .icon-box {
        color: #FFF;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        border-radius: 50%;
      }
      span.padding {
        margin: 0 4px;
      }
    }
    .padding {
      flex: 1;
    }
    .receiver-message, .sender-message {
      display: flex;
      align-items: center;
      width: 100%;
      .icon-box {
        margin: 0 10px;
      }
      .el-icon-loading {
        margin: 0 4px;
      }
      .el-icon-check {
        color: #00796b;
      }
      .el-icon-warning {
        color: rgb(236, 52, 52);
      }
      .el-icon-s-goods, .el-icon-s-opportunity {
        color: rgb(255, 166, 32);
      }
      .el-icon-remove, .el-icon-remove-outline {
        color: rgb(251, 82, 82);
      }
      .el-icon-timer {
        color: rgb(39, 124, 0);
      }
      .el-icon-odometer, .el-icon-link {
        color: rgb(255, 21, 40);
      }
    }
    .message-content {
      background-color: #f1f3f4;
      color: #202124;
      font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
      letter-spacing: .2px;
      max-width: 512px;
      border-radius: 20px;
      box-sizing: border-box;
      display: inline-block;
      padding: 10px 16px;
      position: relative;
      word-wrap: break-word;
      position: relative;
      text-align: left;
      .contact-info {
        position: absolute;
        bottom: -20px;
        color: #5f6368;
        font: 400 12px/16px Roboto, "Helvetica Neue", sans-serif;
        letter-spacing: .3px;
        white-space: nowrap;
        margin: 0;
        display: flex;
        align-items: center;
      }
    }
  }
  .send-btns {
    display: flex;
    align-items: center;
    height: 56px;
    padding: 2px 10px 2px;
    border-top: 1px solid rgba(0,0,0,0.12);
    position: relative;
    .delay-mask {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ebebebe6;
      z-index: 1;
      cursor: pointer;
      .el-loading-spinner i, .el-loading-text {
        color: #00796b;
      }
      p.timeout-text {
        font-size: 14px;
        font-weight: bold;
        color: #00796b;
      }
      .delay-time {
        display: inline-block;
        width: 20px;
        cursor: text;
      }
    }
    .can-send {
      color: #00796b;
    }
    .icon-box, .send-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .icon-box:hover {
      background: rgba(0, 0, 0, 0.04);
    }
    .bi {
      font-size: 16px;
      margin: 0 6px;
      padding: 10px;
    }
    .send-input::-webkit-scrollbar {
      width:2px;
    }
    .send-input::-webkit-scrollbar-track {
      border-radius:0px;
    }
    .send-input {
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      .input-message {
        overflow-y: auto;
        scroll-behavior: smooth;
        outline: none;
        border: none;
        width: 100%;
        padding: 18px 6px;
        background: none;
        color: rgba(0, 0, 0, 0.87);
        resize: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-family: Roboto, Helvetica Neue, sans-serif;
        vertical-align: top;
      }
    }
  }
  .send-btns {
    display: flex;
    position: relative;
    overflow-x: hidden;
    .send-input {
      flex: 1;
    }
    .limit-char {
      position: absolute;
      right: 40px;
      bottom: 4px;
      font-size: 10px;
      background-color: rgba(255, 255, 255, 0.882);
    }
  }
}
</style>
    