import { getAccount } from '@/api/account'
import { recharge } from '@/api/billings'

const state = {
    integral: 0,
    accountId: null,
    discount: null
}

const mutations = {
  SET_ACCOUNT: (state, data) => {
    const {integral, accountId} = data
    state.integral = integral
    state.accountId = accountId
  },
  UPDATE_ACCOUNT: (state, integral) => {
    state.integral = (parseFloat(state.integral) + parseFloat(integral)).toFixed(2)
  },
  CLEAN_UP: (state) => {
    state.integral = 0
    state.accountId = null
  }
}

const actions = {
  cleanUp({ commit }) {
    commit('CLEAN_UP')
  },
  updateAccount({ commit }, number) {
    return new Promise((resolve, reject) => {
      recharge({ 
        number
      }).then((res) => {
        commit('UPDATE_ACCOUNT', res.data.integral)
        resolve(res)
      }).catch((e) => {
        reject(e.message)
      })
    })
  },
  getAccount({ commit }, param) {
    return new Promise((resolve, reject) => {
      getAccount(param.userId).then(response => {
        const { data } = response
        commit('SET_ACCOUNT', data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
