import request from '@/utils/request'
import requestNoLoading from '@/utils/requestNoLoading'
import { insertMessage  } from '@/utils/indexdb'
import { formatDate } from '@/utils/time'

export async function send(info) {
  return new Promise((resolve, reject) => {
    requestNoLoading({
      url: '/messages',
      method: 'post',
      data: info
    }).then((response) => {
      resolve(response)
      insertMessage(info.fullNumber, info.to, {
        body: info.text,
        from: info.number,
        isLeft: false,
        status: info.status,
        to: info.to,
        messageId: info.messageId,
        time: formatDate(new Date())
      })
    }).catch((e) => {
      reject(e)
    })
  })
}


export async function getUnreadMessage() {
  return new Promise((resolve, reject) => {
    request({
      url: '/messages',
      method: 'get'
    }).then((response) => {
      if (response.code === 200) {
        resolve(response.data)
      } else {
        reject(response.message)
      }
    }).catch((e) => {
      reject(e)
    })
  })
}

export async function deleteUnreadMessage(data) {
  return new Promise((resolve, reject) => {
    request({
      url: '/messages',
      method: 'delete',
      data
    }).then((response) => {
      if (response.code === 200) {
        resolve(response.data)
      } else {
        reject(response.message)
      }
    }).catch((e) => {
      reject(e)
    })
  })
}

export function checkMessage(msg) {
  const { messageId, to, fullNumber } = msg
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      requestNoLoading({
        url: `/messages/${messageId}?to=${to}&from=${fullNumber}`,
        method: 'get'
      }).then(res => {
        if (res.code === 200) {
          resolve(res.data)
        } else {
          reject(res.data)
        }
      }).catch(e => {
        reject(e)
      })
    }, 300)
  })
}

export function receiveMessage(number) {
  return new Promise((resolve, reject) => {
    request({
      url: '/messages',
      method: 'get',
      data: {
        number
      }
    }).then(res => {
      resolve(res)
      for (let i = 0; i < res.items.length; i++) {
        const item = res.items[i];
        insertMessage(number, {
          body: item.body,
          from: number,
          isLeft: true,
          to: item.number,
          date: formatDate(new Date())
        })
      }
    }).catch(e => {
      reject(e)
    })
  })
}

