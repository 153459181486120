import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/users/login',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: '/users/info',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: '/users/logout',
    method: 'post'
  })
}

export function list(index, size) {
  const data = {
    pageSize: size,
    pageNumber: index
  }
  return request({
    url: '/users',
    method: 'get',
    data
  })
}

export function addUser(data) {
  return request({
    url: '/users',
    method: 'post',
    data
  })
}

export function deleteUser(userId) {
  const data = {
    id: userId
  }
  return request({
    url: '/users',
    method: 'delete',
    data
  })
}

export function freezeUser(userId, status) {
  const data = {
    id: userId,
    status: status
  }
  return request({
    url: '/users',
    method: 'put',
    data
  })
}

export function modifyPassword(param) {
  const { password, token } = param
  const data = {
    password,
    token
  }
  return request({
    url: '/users',
    method: 'put',
    data
  })
}

export function modifyUser(params) {
  const data = {
    id: params.userId,
    password: params.password,
    name: params.name
  }
  if (params.expired) {
    data.expired = params.expired.toLocaleDateString()
  }
  return request({
    url: '/users',
    method: 'put',
    data
  })
}
