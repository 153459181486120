import { createPhone, list } from '@/api/phone'

const state = {
  phones: [],
  mutilPhones: [],
  fromPhone: null
}

const mutations = {
  SET_FROM_PHONE: (state, phone) => {
    state.fromPhone = phone
  },
  SET_PHONES: (state, phones) => {
    for (let i = 0; i < phones.length; i++) {
      const phone = phones[i];
      phone.copy = false
    }
    state.phones = phones
  },
  REMOVE_PHONE: (state, phone) => {
    const phones = state.phones
    for (let i = 0; i < phones.length; i++) {
      const item = phones[i]
      if (item.phoneId === phone.phoneId) {
        phones.splice(i,1)
        break
      }
    }
  },
  ADD_PHONE: (state, phone) => {
    const phones = state.phones
    for (let i = 0; i < phones.length; i++) {
      const item = phones[i]
      if (item.phoneId === phone.phoneId) {
        return
      }
    }
    const newPhones = [phone, ...phones]
    state.phones = newPhones
  },
  SET_MUTIL_PHONES: (state, phones) => {
    state.mutilPhones = phones
  },
  REMOVE_MUTIL_PHONE: (state, phone) => {
    const phones = state.mutilPhones
    for (let i = 0; i < phones.length; i++) {
      const item = phones[i]
      if (item === phone) {
        phones.splice(i,1)
        break
      }
    }
  },
  ADD_MUTIL_PHONE: (state, phone) => {
    const phones = state.mutilPhones
    for (let i = 0; i < phones.length; i++) {
      const item = phones[i]
      if (item === phone) {
        return
      }
    }
    const newPhones = [phone, ...phones]
    state.mutilPhones = newPhones
  },
  CLEAN_UP: (state) => {
    state.phones = []
    state.mutilPhones = []
    state.fromPhone = null
  }
}

const actions = {
  cleanUp({ commit }) {
    commit('CLEAN_UP')
  },
  setMutilPhones({ commit }, phones) {
    commit('SET_MUTIL_PHONES', phones)
  },
  addMutilPhones({ commit }, phone) {
    commit('ADD_MUTIL_PHONE', phone)
  },
  removeMutilPhones({ commit }, phone) {
    commit('REMOVE_MUTIL_PHONE', phone)
  },
  updateFromPhone({ commit }, phone) {
    commit('SET_FROM_PHONE', phone)
  },
  getNewPhone({ commit }) {
    return new Promise((resolve, reject) => {
      createPhone().then(response => {
        const { data } = response
        commit('ADD_PHONE', data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getPhones({ commit }) {
    return new Promise((resolve, reject) => {
      list().then(response => {
        const { data: body } = response
        commit('SET_PHONES', body.items)
        resolve(body)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
