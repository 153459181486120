import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import sms from './modules/sms'
import account from './modules/account'
import user from './modules/user'
import phone from './modules/phone'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    sms,
    user,
    phone,
    account
  },
  getters
})
