import { stringToColor } from '@/utils/color'
function getItem(key) {
  const value = localStorage.getItem(key)
  return JSON.parse(value)
}

function setItem(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

export function selectMessage(from, to) {
  const key = 'm_' + from + '_' + to
  return getItem(key) || []
}

export function insertMessage(from, to, message) {
  const key = 'm_' + from + '_' + to
  const messages = getItem(key) || []
  messages.push(message)
  setItem(key, messages)
}

export function updateMessage(message) {
  const { fullNumber:from , to, messageId, status } = message
  const key = 'm_' + from + '_' + to
  const messages = getItem(key) || []
  for (let i = messages.length - 1; i > -1; i--) {
    const item = messages[i];
    if (item.messageId === messageId) {
      item.status = status
      break
    }
  }
  setItem(key, messages)
}

export function clearData(from, to) {
  const lastMessages = getItem('last_message_' + from)
  for (let i = 0; i < lastMessages.length; i++) {
    const element = lastMessages[i];
    if (element.phone === to) {
      lastMessages.splice(i, 1)
    }
  }
  localStorage.removeItem('m_' + from + '_' + to)
  const contacts = getItem('contact_' + from) || []
  const newArr = []
  for (let i = 0; i < contacts.length; i++) {
    const element = contacts[i];
    if (element.phone !== to) {
      newArr.push(element)
    }
  }
  setItem('contact_' + from, newArr)
  setItem('last_message_' + from, lastMessages)
}

const unread_key = 'UNREAD_MESSAGE'

export function loadUnreadMessage() {
  return getItem(unread_key) || []
}

export function addUnreadMessage(message) {
  const messages = getItem(unread_key) || []
  messages.push(message)
  setItem(unread_key, messages)
}

function _updateLastMessage(message) {
  const key = 'last_message_' + message.from
  const items = getItem(key) || []
  const { to, time, body } = message
  let findElement = null
  const timestamp = Date.now()
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item.phone === to) {
      item.content = body
      item.time = time
      item.timestamp = timestamp
      findElement = item
    }
  }
  if (!findElement) {
    items.push({
      time,
      timestamp,
      content: body,
      phone: to
    })
  }
  setItem(key, items)
}

export function loadLastMessages(from) {
  const key = 'last_message_' + from
  return getItem(key) || []
}

export function updateLastMessage(message) {
  _updateLastMessage(message)
}

export function updateLastMessages(messages) {
  for (let i = 0; i < messages.length; i++) {
    const element = messages[i]
    _updateLastMessage(element)
  }
}

export function removeUnreadMessage(messages) {
  const localMessages = getItem(unread_key) || []
  for (let j = 0; j < messages.length; j++) {
    const message = messages[j]
    for (let i = 0; i < localMessages.length; i++) {
      const element = localMessages[i]
      if (element.messageId === message.messageId) {
        localMessages.splice(i, 1)
        break
      }
    }
  }
  setItem(unread_key, localMessages)
}

export function selectContact(number) {
  const contacts = getItem('contact_' + number) || []
  const ret = []
  for (let i = 0; i < contacts.length; i++) {
    const contact = contacts[i]
    ret.push({
      ...contact,
      color: stringToColor(contact.phone)
    })
  }
  return ret
}

export function insertContact(number, phone) {
  const contacts = getItem('contact_' + number) || []
  let hasFind = false
  for (let i = 0; i < contacts.length; i++) {
    const element = contacts[i];
    if (element.phone === phone) {
      if (element.send) {
        element.send++
      } else {
        element.send = 1
      }
      hasFind = true
      break
    }
  }
  if (hasFind) {
    setItem('contact_' + number, contacts)
  } else {
    const item = {
      phone
    }
    item.send = 1
    const arr = [item, ...contacts]
    setItem('contact_' + number, arr)
  }
}

export function insertContacts(number, phones) {
  const messages = getItem('contact_' + number) || []
  const newPhones = []
  for (let j = 0; j < phones.length; j++) {
    const item = phones[j];
    if (messages.includes(item.phone)) {
      continue
    }
    newPhones.push(item)
  }
  const arr = [...newPhones, ...messages]
  setItem('contact_' + number, arr)
  return selectContact(number)
}

export function setContacts(number, contacts) {
  const key = 'contact_' + number
  setItem(key, contacts)
}