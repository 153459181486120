import moment from 'moment'

export function formatDate(date) {
  return moment(date).format('YYYY/MM/DD HH:mm:ss')
}


export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
