<template>
  <div class="phone-box">
    <div :class="`input-number ${fromPhone === null?'':'use'}`">
      <input type="text" placeholder="Enter a name or number" v-model="number">
      <i v-if="fromPhone" @click="clearPhone" class="bi bi-x-lg"></i>
      <div class="number-icon-box">
        <i class="bi bi-chat-square-dots-fill"></i>
      </div>
    </div>
    <div class="call-box fill-wrapper">
      <div @click="phoneClick(item)" :class="`call-phone ${fromPhone && fromPhone.fullNumber === item.fullNumber?'selected':''}`" v-for="(item, index) in unreadPhones" :key="index">
        <el-badge :value="item.unread" class="item" type="primary" :hidden="item.unread<=0">
          <div>
              {{ item.number }}
              <span class="time-left" v-if="item.expired > 3">{{ item.expired }} days left</span>
              <span class="time-left expired-tip" v-else-if="item.expired > 0 && item.expired <= 3">
                {{ item.expired }} days expired
              </span>
              <span class="time-left expired" v-else>already expired</span>
              <i @click.stop="renew(item)" class="el-icon-s-goods"></i>
          </div>
        </el-badge>
        <div class="edit-wrapper">
          <i v-if="item.copy" class="el-icon-check"></i>
          <i v-else @click.stop="copyPhone(item)" class="el-icon-document-copy"></i>
          <!-- <i @click.stop="removePhone(item)" class="el-icon-close"></i> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { stringToColor } from '@/utils/color'
import { renewPhone } from '@/api/phone'
import { selectContact, insertContacts, loadLastMessages } from '@/utils/indexdb'
import { sortHandler } from '@/utils/sort'


export default {
  name: 'phone',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'phones', 
      'fromPhone',
      'unreadMessage'
    ]),
    number: function() {
      return this.fromPhone === null ? '' : this.fromPhone.number
    },
    unreadPhones: function() {
      const phones = this.phones
      const messages = this.unreadMessage
      const stat = {}
      for (let i = 0; i < messages.length; i++) {
        const element = messages[i];
        if (stat[element.from]) {
          stat[element.from]++
        } else {
          stat[element.from]= 1
        }
      }
      for (let i = 0; i < phones.length; i++) {
        const phone = phones[i];
        if (stat[phone.fullNumber]) {
          phone.unread = stat[phone.fullNumber]
        } else {
          phone.unread = 0
        }
      }
      return phones
    }
  },
  created() {
    this.$store.dispatch('phone/getPhones')
  },
  methods: {
    copyPhone(item) {
      item.copy = true
      this.$message.success('copy full number success.')
      navigator.clipboard.writeText(item.fullNumber);
      setTimeout(() => {
        item.copy = false
      }, 1500)
    },
    phoneClick(phone) {
      if (phone.expired < 0) {
        this.$message.warning('phone number expired.')
        return
      }
      if (this.fromPhone) {
        if (this.fromPhone.phoneId !== phone.phoneId) {
          this.$store.dispatch('phone/setMutilPhones', [])
          this.$store.dispatch('sms/UpdateSelectContact', null)
          this._updateClickPhone(phone)
        }
      } else {
        this._updateClickPhone(phone)
      }
    },
    _updateClickPhone(phone) {
      this.addNewContacts(phone.fullNumber)
      this.$store.dispatch('phone/updateFromPhone', phone)
      const contacts = selectContact(phone.fullNumber)
      this.$store.dispatch('sms/SetContact', contacts)
      const lastMessages = loadLastMessages(phone.fullNumber)
      const sortArr = lastMessages.sort(sortHandler('timestamp'))
      this.$store.dispatch('sms/SetLastMessages', sortArr)
    },
    addNewContacts(fullNumber) {
      const contacts = selectContact(fullNumber)
      const messages = this.unreadMessage
      const newContact = {}
      const sets = new Set()
      for (let i = 0; i < contacts.length; i++) {
        const contact = contacts[i];
        sets.add(contact.phone)
      }

      for (let i = 0; i < messages.length; i++) {
        const message = messages[i]
        const { to, from } = message
        if (!sets.has(to) && fullNumber === from) {
          if (!newContact[to]) {
            const item = {
              color: stringToColor(to),
              phone: to
            }
            newContact[to] = item
          }
        }
      }
      const newContacts = []
      let newSet = new Set()
      for (var key in newContact) {
        newContacts.push({
          phone: key,
          color: newContact[key].color
        })
        newSet.add(key)
      }
      if (newContacts.length) {
        insertContacts(fullNumber, newContacts)
      }
    },
    clearPhone() {
      this.$store.dispatch('phone/updateFromPhone', null)
    },
    renew(phone) {
      this.$alert(`Are you sure you want to renew for this ${phone.number}?`, 'Tip', {
        confirmButtonText: 'Yes',
        callback: (e) => {
          if (e === 'cancel') {
            return
          }
          renewPhone(phone.phoneId).then(() => {
            this.$message.success('renew success.')
            phone.expired += 30
          }).catch((e) => {
            this.$message.error(e)
          })
        }
      })
    },
    // removePhone(phone) {
    //   this.$alert(`Do you want to delete ${phone.number}?`, 'Tip', {
    //     confirmButtonText: 'Yes',
    //     callback: (e) => {
    //       if (e === 'cancel') {
    //         return
    //       }
    //       this.$store.dispatch('phone/removePhone', phone)
    //     }
    //   })
    // }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss">
.phone-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 360px;
  z-index: 61;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 6px 2px rgb(66 69 73 / 15%);
  box-shadow: 0 3px 6px 2px rgb(66 69 73 / 15%);
  .fill-wrapper {
    flex: 1;
  }
  .call-box {
    box-sizing: border-box;
    height: 64px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #dadce0;
    overflow-y: scroll;
    p {
      margin: 0px;
      text-align: left;
    }
    .call-title {
      color: #5f6368;
      font: 500 14px/20px "Google Sans", "Helvetica Neue", sans-serif;
      letter-spacing: .25px;
      padding: 10px 16px;
    }
    .call-phone {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 64px;
      color: #202124;
      padding: 10px 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font: 500 14px/20px "Google Sans", "Helvetica Neue", sans-serif;
      letter-spacing: .25px;
      box-sizing: border-box;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: #dadce0;
      i.el-icon-close, i.el-icon-document-copy {
        visibility: hidden;
        margin: 0 6px;
        cursor: pointer;
        color: rgb(200, 199, 199);
      }
      i.el-icon-close:hover ,i.el-icon-document-copy:hover {
        color: rgb(118, 118, 118);
      }
      .el-icon-check {
        margin: 0 6px;
        color: rgb(39, 198, 39);
      }
      .el-badge__content.is-fixed {
        top: 0px;
        right: 6px;
      }
      .time-left {
        font-weight: normal;
        font-size: 10px;
        text-align: left;
        color: rgb(136, 136, 136);
        cursor: pointer;
        margin-left: 10px;
      }
      .edit-wrapper {
        margin-right: 20px;
      }
      .el-icon-s-goods {
        padding: 2px;
        margin: 0 2px;
        font-size: 14px;
        cursor: pointer;
        color: rgb(255, 125, 50);
        border-radius: 50%;
        transition: all .2s linear;
      }
      .el-icon-s-goods:hover {
        background-color: rgb(255, 125, 50);
        color: white;
      }
      .el-icon-s-goods:active {
        color: rgb(255, 125, 50);
        background-color: transparent;
      } 
      .time-left.expired {
        color: rgb(255, 40, 40);
        font-weight: bold;
      }
      .time-left.expired-tip {
        color: rgb(218, 171, 14);
        font-weight: bold;
      }
    }
    .call-phone.selected {
      background-color: #f8f9fa;
    }
    .call-phone:hover {
      background-color: #f8f9fa;
      i {
        visibility: visible;
      }
    }
  }
  .keyboard-switch {
    .bi {
      color: #1a73e8;
    }
  }
  .input-number {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #dadce0;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    height: 64px;
    box-sizing: border-box;
    padding: 3px 16px;
    font: 500 14px/20px "Google Sans", "Helvetica Neue", sans-serif;
    letter-spacing: .25px;
    .number-icon-box {
      background-color: rgba(0,0,0,.12);
      color: rgba(0,0,0,.38);
      border-color: rgba(0,0,0,0);
      border-radius: 18px;
      margin: 6px 8px;
      min-height: 36px;
      min-width: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      .bi {
        font-size: 18px;
      }
    }
    input {
      font: inherit;
      background: rgba(0,0,0,0);
      color: currentColor;
      border: none;
      outline: none;
      padding: 0;
      margin: 0;
      width: 100%;
      max-width: 100%;
      vertical-align: bottom;
      box-sizing: content-box;
    }
  }
  .input-number.use {
    .number-icon-box {
      cursor: pointer;
      background-color: #1a73e8;
      color: rgba(255,255,255,.87);
      box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
    }
    .number-icon-box:hover {
      background-color: #669df6;
    }
  }
}
</style>
    