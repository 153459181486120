import router from './router'
import store from './store'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'
import getPageTitle from '@/utils/get-page-title'

const whiteList = ['/login', '/', '/help', '/404', '/register']

router.beforeEach(async(to, from, next) => {
  document.title = getPageTitle(to.meta.title)
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/main' })
    } else {
      try {
        await store.dispatch('user/getInfo')
        next()
      } catch (error) {
        await store.dispatch('user/resetToken')
        if (whiteList.indexOf(to.path) !== -1) {
          Message.error(error || 'Has Error')
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next('/')
    }
  }
})
