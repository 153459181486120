import Cookies from 'js-cookie'

const TokenKey = 'gv_token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setAccount(user) {
  const { username, password } = user
  Cookies.set('Name', username)
  Cookies.set('Password', password)
}

export function getAccount() {
  const username = Cookies.get('Name')
  const password = Cookies.get('Password')
  return {
    username,
    password
  }
}
