import request from '@/utils/request'

export function getAccount(userId) {
  return request({
    url: `/accounts?userId=${userId}`,
    method: 'get'
  })
}

export function getDiscount(accountId) {
  return request({
    url: `/accounts/${accountId}`,
    method: 'get'
  })
}