import request from '@/utils/request'

export function list() {
  return request({
    url: '/phones',
    method: 'get'
  })
}

export function renewPhone(phoneId) {
  return request({
    url: `/phones/${phoneId}`,
    method: 'put'
  })
}

export function createPhone() {
  return request({
    url: '/phones',
    method: 'post'
  })
}

// export function deletePhone(phoneId) {
//   return request({
//     url: '/phones/'+phoneId,
//     method: 'delete'
//   })
// }
